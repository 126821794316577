import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../../layouts/default/layout'
import SEO from '../../components/seo'
import Wysiwyg from '../../components/wysiwyg/Wysiwyg'

export default ({ data: { page } }) => {
    const { title, scrapbook } = page.acf
    const firstName = page.title.split(' ')[0]
    return (
        <Layout className="TeamMemberPage">
            <SEO
                yoast={page.yoast}
                title={page.title}
                description={page.content}
                dateModified={page.modified}
                datePublished={page.date}
            />
            <main>
                <section className="container-fluid px-yd mb-yd mt-yd">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <h1
                                className="mb-4"
                                dangerouslySetInnerHTML={{
                                    __html: page.title,
                                }}
                            />
                            <div className="type-label text-secondary mb-3">
                                {title}
                            </div>
                            <Wysiwyg>{page.content}</Wysiwyg>
                        </div>
                        <div className="col-12 col-md-6">
                            <Img
                                fluid={
                                    page.featuredMedia.localFile.childImageSharp
                                        .fluid
                                }
                                alt={page.featuredMedia.alt_text}
                                title={page.featuredMedia.title}
                            />
                        </div>
                    </div>
                </section>
                <section className="scrapbook container-fluid px-yd mb-yd">
                    <div className="row">
                        <div className="col-12 col-md-3 col-lg-4">
                            <h3>{`${firstName}'s Scrapbook`}</h3>
                        </div>
                        <div className="col-12 col-md-9 col-lg-8">
                            <ul className="list-unstyled">
                                {scrapbook &&
                                    Array.isArray(scrapbook) &&
                                    scrapbook.map(
                                        ({ image, description }, index) => {
                                            return (
                                                image && (
                                                    <li
                                                        key={index}
                                                        className="mb-yd"
                                                    >
                                                        <figure>
                                                            <Img
                                                                fluid={
                                                                    image
                                                                        .localFile
                                                                        .childImageSharp
                                                                        .fluid
                                                                }
                                                                alt={
                                                                    image.alt_text
                                                                }
                                                                title={
                                                                    image.title
                                                                }
                                                            />
                                                            <figcaption className="type-label text-secondary mt-3 text-center">
                                                                {`${description}`}
                                                            </figcaption>
                                                        </figure>
                                                    </li>
                                                )
                                            )
                                        }
                                    )}
                            </ul>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export const pageQuery = graphql`
    query($id: String!) {
        page: wordpressPage(id: { eq: $id }) {
            title
            content
            ...YoastPage
            featuredMedia: featured_media {
                alt_text
                title
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 2000 maxHeight: 2500 cropFocus: SOUTH) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            acf {
                title
                scrapbook {
                    description
                    image {
                        alt_text
                        title
                        localFile {
                            childImageSharp {
                                fluid(quality:95) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
